<template>
  <div v-if="!ready">
    <InnerLoading></InnerLoading>
  </div>
  <div v-else>
    
    <!-- <template v-if="roleType === 'student' && campus === 32320">
      <div class="row mt-1">
        <div class="col-md-12 mt-1" v-if="epbClassCheck() === '3YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo1}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 1</div>
            </div>
          </a>
        </div>

        <div class="col-md-12 mt-1" v-else-if="epbClassCheck() === '4YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo2}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 2</div>
            </div>
          </a>
        </div>

        <div class="col-md-12 mt-1" v-else-if="epbClassCheck() === '5YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo3}`" target="_blank">
              <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 3</div>
            </div>
          </a>
        </div>
      </div>
    </template>
    <template v-else-if="roleType === 'teacher' && campus === 32320">
      <div class="row mt-1">
        <div class="col-md-6 mt-1" v-if="epbClassCheck() === '3YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo1}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 1</div>
            </div>
          </a>
        </div>
        <div class="col-md-6 mt-1" v-else-if="epbClassCheck() === '4YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo2}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 2</div>
            </div>
          </a>
        </div>
        <div class="col-md-6 mt-1" v-else-if="epbClassCheck() === '5YAŞ'">
          <a :href="`https://englishplaybox.net/#/${gndepbdemo3}`" target="_blank">
              <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEnglishPlayBox]"></div>
              <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 3</div>
            </div>
          </a>
        </div>
        <div class="col-md-6 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </div>
    </template> -->
    <div v-if="roleType === 'student' && Number(grade) >= 3 && Number(grade) < 8" class="row mt-1">
      <div v-if="hexagoniaReady" class="col-md-6 mt-1">
        <a :href="`https://www.hexagonia.com.tr${this.hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div>
      <div v-else class="col-md-6 mt-1">
        <a @click="hexaCheck()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div>
    </div>
    <div v-if="roleType === 'student' && Number(grade) >=1 && Number(grade) <= 4" >
      <div class="col-md-4 mt-1">
        <a :href="`https://www.kidzwonder.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div style="background-size: 40% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
            <div :class="$style.txtPlatform">AGK KIDZ</div>
          </div>
        </a>
      </div>
    </div>
    <div v-if="roleType === 'student' && Number(grade) === 1" class="row mt-1">
      <div class="col-md-4 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
       <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.kidzwonder.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div style="background-size: 40% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
            <div :class="$style.txtPlatform">AGK KIDZ</div>
          </div>
        </a>
      </div> -->
    </div>    
    <div v-else-if="roleType === 'student' && Number(grade) === 5" class="row mt-1">
      <!-- <div v-if="hexagoniaReady" class="col-md-6 mt-1">
        <a :href="`https://www.hexagonia.com.tr${this.hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div>
      <div v-else class="col-md-6 mt-1">
        <a @click="hexaCheck()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>

      
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.agkcity.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
            <div :class="$style.txtPlatform">AGK CITY</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a @click="checkHll()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div style="background-size: 30% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            <div :class="$style.txtPlatform">HIGHLIGHTS LIBRARY</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
      <!-- <div class="col-md-6 mt-1">
        <a @click="lc()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
              <div :class="$style.txtPlatform">LOG COMPASS</div>
            </div>
          </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'student' && Number(grade) >= 3 && Number(grade) < 8" class="row mt-1">
      <div v-if="Number(grade) === 6" class="col-md-4 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <div v-else class="col-md-4 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>


      
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.agkcity.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
            <div :class="$style.txtPlatform">AGK CITY</div>
          </div>
        </a>
      </div>
      <div class="col-md-6 mt-1">
        <a @click="checkHll()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            <div :class="$style.txtPlatform">HIGHLIGHTS LIBRARY</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-4 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
      <!-- <div class="col-md-4 mt-1">
        <a @click="lc()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
              <div :class="$style.txtPlatform">LOG COMPASS</div>
            </div>
          </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'student' && Number(grade) < 5 && Number(grade) >= 2" class="row mt-1">
      
      <!-- <div class="col-md-4 mt-1">
        <a href="https://app.literacyplanet.com/login">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnliteracyPlanet]"></div>
            <div :class="$style.txtPlatform">LITERACY PLANET</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.hexagonia.com.tr${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <template v-if="Number(grade) === 4">
        <div class="col-md-4 mt-1">
          <a :href='ActivityBooks()' target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div style="background-size: 25% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
              <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
            </div>
          </a>
        </div>
      <!-- <div v-if="hexagoniaReady" class="col-md-4 mt-1">
        <a :href="`https://www.hexagonia.com.tr${this.hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div> -->
      <!-- <div v-else class="col-md-4 mt-1">
        <a @click="hexaCheck()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-4 mt-1">
          <a @click="lc()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
              <div :class="$style.txtPlatform">LOG COMPASS</div>
            </div>
          </a>
      </div> -->
      </template>
      <template v-else-if="Number(grade) === 3">
        <div class="col-md-4 mt-1">
          <a :href='ActivityBooks()' target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div style="background-size: 30% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
              <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
            </div>
          </a>
        </div>
        <!-- <div v-if="hexagoniaReady" class="col-md-4 mt-1">
          <a :href="`https://www.hexagonia.com.tr${this.hexaDeStringText}`" target="_blank">
              <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
              <div :class="$style.txtPlatform">HEXAGONIA</div>
            </div>
          </a>
        </div>
        <div v-else class="col-md-4 mt-1">
          <a @click="hexaCheck()">
              <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
              <div :class="$style.txtPlatform">HEXAGONIA</div>
            </div>
          </a>
        </div> -->
        <!-- <div class="col-md-4 mt-1">
          <a @click="lc()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
              <div :class="$style.txtPlatform">LOG COMPASS</div>
            </div>
          </a>
        </div> -->
      </template>
      <template v-else>
        <div class="col-md-6 mt-1">
          <a :href='ActivityBooks()' target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div style="background-size: 30% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
              <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
            </div>
          </a>
        </div>
        <!-- <div class="col-md-6 mt-1">
          <a @click="lc()">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
              <div :class="$style.txtPlatform">LOG COMPASS</div>
            </div>
          </a>
        </div> -->
      </template>
    </div>
    <div v-else-if="roleType === 'student' && Number(grade) === 8" class="row mt-1">
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.agkcity.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
            <div :class="$style.txtPlatform">AGK CITY</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://www.hexagonia.com.tr${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <div class="col-md-6 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div style="background-size: 30% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
    </div>
    <div v-else-if="roleType === 'student' && Number(grade) !== 9 && Number(grade) !== 10" class="row mt-1">
      <!-- <div class="col-md-12 mt-1">
        <a href="https://app.literacyplanet.com/login" target="blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnliteracyPlanet]" style="background-size: 35% auto;"></div>
            <div :class="$style.txtPlatform">LITERACY PLANET</div>
          </div>
        </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'student' && Number(grade) === 9" class="row mt-1">
      <div class="col-md-4 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a @click="pb()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnPersonalBest]"></div>
            <div :class="$style.txtPlatform">PERSONAL BEST</div>
          </div>
        </a>
      </div>

      <div class="col-md-4 mt-1">
           <a :href="`https://www.deutschbesten.com/#/${hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              <div :class="$style.txtPlatform">DEUTSCHBESTEN</div>
            </div>
          </a>
      </div>

    </div>
    <div v-else-if="roleType === 'student' && Number(grade) === 10" class="row mt-1">
      <div class="col-md-4 mt-1">
        <a :href="`https://www.youtube.com/channel/UCs7oKT7lVe_oGbDnIxbhIKg/playlists?view=1&sort=dd&shelf_id=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnYoutube]"></div>
            <div :class="$style.txtPlatform">GO-Gündoğdu Online</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a :href='ActivityBooks()' target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">ACTIVITY BOOKS</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a @click="pb()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnPersonalBest]"></div>
            <div :class="$style.txtPlatform">PERSONAL BEST</div>
          </div>
        </a>
      </div>  
      
      <div class="col-md-4 mt-1">
           <a :href="`https://www.deutschbesten.com/#/${hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              <div :class="$style.txtPlatform">DEUTSCHBESTEN</div>
            </div>
          </a>
      </div>
    </div>

    <template v-if="roleType === 'student' && this.rlpCode != null && this.rlpCode != '###'">
      <div  class="row mt-1">
      <div class="col-md-6 mt-1">
        <a data-toggle="modal" data-target="#rlpDescription">             
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div>
          </div>
        </a>
      </div>
    </div>
    </template>
    <template v-else-if="roleType === 'student' && this.rlpCode == '###'">
      <div  class="row mt-1">
      <div class="col-md-6 mt-1">
        <a data-toggle="modal" data-target="#rlpDescription">   
        <!-- <a @click="RLP()" target="_blank"> -->
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div>
          </div>
        </a>
      </div>
      </div>
    </template>

    


    <div v-else-if="roleType === 'teacher' && userDetails.lang === 'de' && userDetails.email === 'aydaculha@gundogdu.k12.tr'" class="row mt-1">
      <!-- <div class="col-md-4 mt-1">
        <a :href="`https://admin.karnevall.com/login${primaryQueryString}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKarnevall]"></div>
            <div :class="$style.txtPlatform">KARNEVALL</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-4 mt-1">
        <a :href="`https://drive.google.com/drive/folders/1Mfg-4F718VRJbmlVy2FzFVxL2XmN6HhW?usp=sharing`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">READERS</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a :href="`https://drive.google.com/drive/folders/13QmZM2M3zuu6BijB9Z3pKYbXlmr1lgB9?usp=sharing`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">HANDOUTS</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1">
        <a :href="TeacherResources()" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
            <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
          </div>
        </a>
      </div>
      <div class="col-md-12 mt-1">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingHexaReport}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div>
      <!-- <div class="col-md-6 mt-1" v-if="levelName === 'İlkokul'">
        <a @click="lc()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
            <div :class="$style.txtPlatform">LOG COMPASS</div>
          </div>
        </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'teacher' && userDetails.lang === 'de'" class="row mt-1">
      <template v-if="levelName === 'Ortaokul'">
        <div class="col-md-4 mt-1">
          <a :href="`https://drive.google.com/drive/folders/1Mfg-4F718VRJbmlVy2FzFVxL2XmN6HhW?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="`https://drive.google.com/drive/folders/13QmZM2M3zuu6BijB9Z3pKYbXlmr1lgB9?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnActivityBooks]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <template v-else-if="levelName === 'Lise'">
        <div class="col-md-4 mt-1">
          <a :href="`https://drive.google.com/drive/folders/10ozYCLdPwB8EkVy2Wq9M6qMVaF5Mv7a9?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="`https://drive.google.com/drive/folders/1SlHNiFe06p1CWoEFUamuefbisq77uZCx?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <template v-else-if="levelName === 'İlkokul'">
        <!-- <div class="col-md-6 mt-1">
          <a :href="`https://drive.google.com/drive/folders/139JDyzQWNjHxxUDKCuywiR-kBSwtY-wP?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-6 mt-1">
          <a :href="`https://drive.google.com/drive/folders/1rNbEFbSjrY4EgQ35ySEsgKZflVYWz1fV?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div> -->
        <div class="col-md-6 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <div class="col-md-6 mt-1" v-if="findTeacGrade(5,7)">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingHexaReport}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
      </div>

      <!-- <div class="col-md-6 mt-1" v-if="findTeacGrade(9,10)">
           <a :href="`https://www.deutschbesten.com/#/${hexaDeStringText}`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
              <div :class="$style.txtPlatform">DEUTSCHBESTEN</div>
            </div>
          </a>
      </div> -->
      <!-- <div class="col-md-6 mt-1" v-if="levelName === 'İlkokul'">
        <a @click="lc()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
            <div :class="$style.txtPlatform">LOG COMPASS</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-6 mt-1" v-if="levelName === 'İlkokul'">
        <a :href="`https://admin.karnevall.com/login${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKarnevall]"></div>
            <div :class="$style.txtPlatform">KARNEVALL</div>
          </div>
        </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'teacher' && userDetails.lang === 'en'" class="row mt-1">
      <template v-if="levelName === 'Anaokul'">
        <div class="col-md-12 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <template v-if="levelName === 'Ortaokul'">
        <!-- <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/2rmjes7wewuao24/AABShzIRRWnYPVTFcSatnrGYa?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/8qbmmtc0274x7f3/AAA8n3QI9TxpfUcoYVfa1mYYa?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div> -->
        <!-- <div class="col-md-6 mt-1" v-if="Number(grade) !== 8">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingHexaReport}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            <div :class="$style.txtPlatform">HEXAGONIA</div>
          </div>
        </a>
        </div> -->
        <div class="col-md-6 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
       
      </template>
      <template v-else-if="levelName === 'Lise'">
        <!-- <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/s2p6rg464cd5qw4/AABoP1kj70ALG1XIKL0oiWTQa?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/byb3w08v29saomx/AAAU5FjYj5a2eZi3CCqWwswva?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div> -->
        <div class="col-md-6 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <template v-else-if="levelName === 'İlkokul'">
        <!-- <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/ipl27mw07cwvgwm/AACZO-Ucf6mpYXXjcFPCOLkBa?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
              <div :class="$style.txtPlatform">READERS</div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mt-1">
          <a :href="`https://www.dropbox.com/sh/mvqjjpme2aj1x0h/AAAYvuCDcT5T2A4EOAQKS_92a?dl=0`" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHandouts]"></div>
              <div :class="$style.txtPlatform">HANDOUTS</div>
            </div>
          </a>
        </div> -->
        <div class="col-md-4 mt-1">
          <a :href="TeacherResources()" target="_blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTeacherResources]"></div>
              <div :class="$style.txtPlatform">TEACHER RESOURCES</div>
            </div>
          </a>
        </div>
      </template>
      <div class="col-md-4 mt-1" v-if="levelName === 'İlkokul'" data-toggle="modal" data-target="#exampleModalCenter1">
        <!-- <a :href="`https://www.uesportals.com/#/login/signin${querystingKidzReport}`" target="_blank"> -->
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnContents]"></div>
            <div :class="$style.txtPlatform">CONTENTS</div>
          </div>
        <!-- </a> -->
      </div>
      <!-- <div class="col-md-4 mt-1" v-if="levelName === 'İlkokul'">
        <a @click="lp()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnliteracyPlanet]" style="background-size: 55% auto;"></div>
            <div :class="$style.txtPlatform">LITERACY PLANET</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-4 mt-1" v-if="levelName === 'İlkokul'">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingKidzReport}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]" style="background-size: 35% auto;"></div>
            <div :class="$style.txtPlatform">AGK KIDZ</div>
          </div>
        </a>
      </div>
      <!-- <div class="col-md-4 mt-1" v-if="levelName === 'İlkokul' && grade != 1">
        <a @click="lc()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
            <div :class="$style.txtPlatform">LOG COMPASS</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-4 mt-1" v-if="levelName === 'Ortaokul'">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingAgkReport}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
            <div :class="$style.txtPlatform">AGK CITY</div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mt-1" v-if="levelName === 'Ortaokul'">
        <a @click="checkHll()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div style="background-size: 35% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            <div :class="$style.txtPlatform">HIGHLIGHTS</div>
          </div>
        </a>
      </div> -->
       <!-- <div class=" mt-1" v-if="levelName === 'Ortaokul'" :class="Number(grade) === 8 ? 'col-md-6' : 'col-md-4'">
        <a @click="lc()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLogCompass]"></div>
            <div :class="$style.txtPlatform">LOG COMPASS</div>
          </div>
        </a>
      </div> -->
      <!-- <div class="col-md-6 mt-1" v-if="levelName === 'Lise'">
        <a :href="`http://46.101.72.250/login${projectUmbrella}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformProjectUmbrella]"></div>
            <div :class="$style.txtPlatform">THE PROJECT UMBRELLA CONTENTS</div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1" v-if="levelName === 'Lise'">
        <a @click="pb()">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnPersonalBest]"></div>
            <div :class="$style.txtPlatform">PERSONAL BEST</div>
          </div>
        </a>
      </div>
    </div>

    <div v-if="roleType === 'student' && Number(grade) >= 2 && Number(grade) <= 10" class="row mt-1">
      <!-- <div class="col-md-6 mt-1" >
        <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment]"></div>
            <div :class="$style.txtPlatform">myEDUASSESSMENT</div>
          </div>
        </a>
      </div> -->
    </div>
    <div v-else-if="roleType === 'teacher' && Number(grade) >= 2 && Number(grade) <= 10"  class="row mt-1">  
      <!-- <div class="col-md-6 mt-1">
        <a :href="`https://report.myeduassessment.com/#/login${querystingtext}`" target="blank">
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment]"></div>
            <div :class="$style.txtPlatform">myEDUASSESSMENT</div>
          </div>
        </a>
      </div> -->
    </div>

    <div v-if="roleType =='teacher' && this.rlpUse != null" class="row mt-1">  
      <div class="col-md-6 mt-1" v-if="roleType =='teacher' && this.rlpUse != null">
        <a :href="`https://richmondlp.com/login`" target="blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div>
          </div>
        </a>
      </div>
    </div>

    <div v-if="roleType =='teacher' && findTeacGrade(1,7)" class="row mt-1"> 
      <div class="col-md-6 mt-1" v-if="roleType =='teacher' && findTeacGrade(1,7)">
        <a :href="`https://www.uesportals.com/#/login/signin${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReports]"></div>
            <div :class="$style.txtPlatform">REPORT</div>
          </div>
        </a>
      </div>
    </div>
<!-- 
    <div if="roleType === 'student'" class="row mt-1">
      <template>
        <div class="col-12 mt-1">
        
            <div :class="$style.divPlatform" :style='`border-color:${this.switchColor} !important`'>
              <p>
            Richmond Learning Platform Aktivasyon Adımları: <br>
- Öncelikle aşağıdaki "Kodu Kopyala" butonuna basarak kitap kodunuzu kopyalayınız.
- Yukarıda gördüğünüz Richmond Learning Platform simgesine tıklayınız ve siteye geçiş yapınız.
- Açılan sayfa, sizden kod girmenizi isteyecektir. Kopyaladığınız kodu "Please enter a code" alanına yapıştırınız.
- Kodu yapıştırdıktan sonra sağ alt bölümde görünen "Add Product" butonuna tıklayarak kodu aktifleştiriniz.
- UYARI : Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.

          </p>

            </div>

        </div>
      </template>
    </div> -->


    <div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Select Your Report Platform</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mt-1">
                <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                    <div :class="$style.txtPlatform">AGK KIDZ - 1</div>
                  </div>
                </a>
              </div>
              <div class="col-md-12 mt-1">
                <a :href="`https://www.kidzwonder.com${this.gndkwdemo2}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                    <div :class="$style.txtPlatform">AGK KIDZ - 2</div>
                  </div>
                </a>
              </div>
              <div class="col-md-12 mt-1">
                <a :href="`https://www.kidzwonder.com${this.gndkwdemo3}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                    <div :class="$style.txtPlatform">AGK KIDZ - 3</div>
                  </div>
                </a>
              </div>
              <div class="col-md-12 mt-1">
                <a :href="`https://www.kidzwonder.com${this.gndkwdemo4}`" target="_blank">
                  <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
                    <div :class="$style.txtPlatform">AGK KIDZ - 4</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rlpDescription" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Richmond Learning Platform Aktivasyon Adımları</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                <!-- <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank"> -->
                  <div :class="$style.divPlatform">
                    <template v-if="this.rlpCode != null && this.rlpCode != '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <b>"Kodu Kopyala"</b> butonuna basarak kitap kodunuzu kopyalayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kopyaladığınız kodu <b>"Please Enter a Code"</b> alanına yapıştırınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Kodu yapıştırdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">7.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>
                    <template v-else-if="this.rlpCode == '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <b>"Please Enter a Code"</b> alanına yazınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b>	Kodu yazdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>


                  </div>
                <!-- </a> -->
              </div>

              <div class="col-md-12 mt-1" v-if="this.rlpCode != null && this.rlpCode != '###'">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px" ref="code" v-on:focus="$event.target.select()" >{{rlpCode}}</p>
                        <input type="hidden" id="rlp-code" :value="rlpCode">
                      </div>
                      
                      <div class="col-4">                      
                        <button type="button" @click="copyCode()" class="btn btn-primary">Kodu Kopyala</button>
                      </div>
                    </div>                    
                  </div>
              </div>
              <div class="col-md-6 mt-1">
                <!-- <a data-toggle="modal" data-target="#rlpDescription"> -->
                  <a @click="RLP()" target="_blank" data-dismiss="modal">
                  
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode != null && this.rlpCode != '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode == '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapState, mapGetters } from 'vuex';
import userService from '@/services/user';
import InnerLoading from '@/components/InnerLoading';
import CryptoJS from 'crypto-js';

export default {
  name: 'platforms',
  data() {
    return {
      rlpUse: null,
      rlpCode: null,
      grade: '',
      roleType: '',
      querystingtext: '',
      primaryQueryString: '',
      secondaryQueryString: '',
      ready: true,
      gndkwdemo1: null,
      gndkwdemo2: null,
      gndkwdemo3: null,
      gndkwdemo4: null,
      gndepbdemo1: null,
      gndepbdemo2: null,
      gndepbdemo3: null,
      hexagoniaReady: false,
      personalbestUser: '',
      personalbestPass: '',
      logCompassUser: '',
      logCompassPass: '',
      logCompassSchool: '',

      class_id: null,
      teacherGrades:[],
    };
  },
  components: {
    InnerLoading,
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    ...mapGetters(['switchColor']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    copyCode(){
      let testingCodeToCopy = document.querySelector('#rlp-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      // try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
      //   alert('Testing code was copied ' + msg);
      // } catch (err) {
      //   alert('Oops, unable to copy');
      // }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    findTeacGrade(grade1, grade2){
        const grade = this.teacherGrades.filter((x) => (x >= grade1 && x <= grade2))
        if (grade.length > 0) return true; else return false;
    },

    epbClassCheck(){
      if(this.class_id === 78797 || this.class_id === 78798){
        return '3YAŞ' ;
      } else if(this.class_id === 78799 || this.class_id === 78800 || this.class_id === 78801 || this.class_id === 78802 || this.class_id === 78803 || 
      this.class_id === 78804 || this.class_id === 78805 || this.class_id === 78806 || this.class_id === 78807 || this.class_id === 78808 || 
      this.class_id === 78809 || this.class_id === 78810){
        return '4YAŞ' ;
      } else if(this.class_id === 78811 || this.class_id === 78813 || this.class_id === 78814 || this.class_id === 78815 || this.class_id === 78816 || 
      this.class_id === 78817 || this.class_id === 78818 || this.class_id === 78819 || this.class_id === 78820 || this.class_id === 78821 || 
      this.class_id === 78822 || this.class_id === 78823 || this.class_id === 78824 || this.class_id === 78825){
        return '5YAŞ' ;
      }
    },
    async checkHll() {
      this.ready = false;
      const userDetails = await userService.getStudentDetails();
      const userId = userDetails.data.user_id;
      const firstName = userDetails.data.first_name;
      const lastName = userDetails.data.last_name;
      const className = userDetails.data.organization.find(x => x.type === 'grade').name;
      const userType = userDetails.data.role_type;
      const key = 'jTJMgfWO/UHczbEyVvuDmw==';
      window.go_hll(userId, userId, firstName, lastName, className, userType, key);
      this.ready = true;
    },
    async hexaCheck() {
      // const username = this.username.split('@')[0];
      // eslint-disable-next-line
      // const hexaUserName = username + '-de@agk88.com';
      // :href="`https://www.hexagonia.com.tr${hexaDeStringText}`" target="_blank"
      // const res = await userService.checkHexagonia(hexaUserName);
      // if (res.data.success) {
        window.open(`https://www.hexagonia.com.tr${this.hexaDeStringText}`, '_blank');
      // } else {
      // swal.fire('Error!', "Almanca Dijital Kaydınız Bulunmamaktadır. Kaydınızı uesdigital.com'dan yapabilirsiniz.", 'error');
      // swal.fire('Error!', "Kaydınız tamamlandı. En kısa sürede aktif olacaktır.İlerleyen günlerde tekrar deneyiniz.", 'error');
      // }
    },
    getRLPCustomCode(){

      if (this.class_id ==	73422	||this.class_id ==	90262	|| this.class_id ==	73425	||this.class_id ==	90264	||this.class_id ==	90265	||this.class_id ==	73428	||
          this.class_id ==	73431	||this.class_id ==	99645	||this.class_id ==	73432	||this.class_id ==	99646	||this.class_id ==	90270	||this.class_id ==	73434	||
          this.class_id ==	73437	||this.class_id ==	90267	||this.class_id ==	89803	||this.class_id ==	73439	||this.class_id ==	99647	||this.class_id ==	99648	||
          this.class_id ==	73443	||this.class_id ==	90289	||this.class_id ==	73446	||this.class_id ==	90284	||this.class_id ==	73448	||this.class_id ==	73449	||
          this.class_id ==	73450	||this.class_id ==	90287	||this.class_id ==	73452	||this.class_id ==	90283	||this.class_id ==	99650	||this.class_id ==	99651	||
          this.class_id ==	99652	||this.class_id ==	90303	||this.class_id ==	73462	||this.class_id ==	73465	||this.class_id ==	73455	||this.class_id ==	73459	||
          this.class_id ==	90307	||this.class_id ==	90308	||this.class_id ==	73456	||this.class_id ==	90310	||this.class_id == 100890 ||this.class_id ==	73670	||
          this.class_id ==	73672	||this.class_id ==	94259	||this.class_id ==	73673	||this.class_id ==	73677	||this.class_id ==	94260	||this.class_id ==	73678	||
          this.class_id ==	94261	||this.class_id ==	73679	||this.class_id ==	73680	||this.class_id ==	73681	||this.class_id ==	73682	||this.class_id ==	73683	||
          this.class_id ==	94262	) 
          this.rlpCode ='###'

      if (this.class_id ==	73438	||this.class_id ==	90281	||this.class_id ==	73440	||this.class_id ==	73441	||this.class_id ==	73442	||
          this.class_id ==	90282	||this.class_id ==	73444	||this.class_id ==	73445	||this.class_id ==	73447	||this.class_id ==	99649	||
          this.class_id ==	90285	||this.class_id ==	90286	||this.class_id ==	73451	||this.class_id ==	90288	)
          this.rlpCode ='EU4C38J8NVPQ6Y48AUTM'

      if (this.class_id ==	73454	||this.class_id ==	73457	||this.class_id ==	73461	||this.class_id ==	73464	||this.class_id ==	73453	||
          this.class_id ==	73458	||this.class_id ==	90304	||this.class_id ==	90305	||this.class_id ==	90306	||this.class_id ==	73460	||
          this.class_id ==	73463	||this.class_id ==	90309	||this.class_id ==	90311	) 
          this.rlpCode ='FF9VKXB7R22AYTN7MS2J'

      if (this.class_id ==	90041	||this.class_id ==	73423	||this.class_id ==	73424	||this.class_id ==	90263	||this.class_id ==	73426	||
          this.class_id ==	73427	||this.class_id ==	90266	||this.class_id ==	73429	||this.class_id ==	73430	||this.class_id ==	90268	||
          this.class_id ==	90269	||this.class_id ==	73433	||this.class_id ==	73435	||this.class_id ==	73436	) 
          this.rlpCode ='JBMVGR83NNDPC2C25STW'
    },

    async setData() {
      this.ready = false;
      if (this.userDetails) {
        this.grade = this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] !== '100' ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1];
        this.class_id = this.userDetails.organization.find(x => x.type === 'grade').id;
        this.campus = this.userDetails.organization.find(x => x.type === 'campus').id;
        this.roleType = this.userDetails.role_type;

        console.log(this.grade,this.roleType)
        this.rlpUse = this.userDetails.organization.find(x => x.type === 'campus').rlp_institute_code
        this.rlpCode = this.userDetails.rlp_code ? this.userDetails.rlp_code : null
        
        if ( this.roleType == 'teacher'){
          const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
          for (let key in teacherGrades){
            this.teacherGrades.push(teacherGrades[key].name.split("-")[0])
          }
        }
        this.getRLPCustomCode();// bazı levellar için farklı kodlar var. Kodların biri lms üzeinden girildi, diğeri için burada sınıf id ye göre kod değiştiriliyor.

        if (this.userDetails.email === 'aydaculha@gundogdu.k12.tr') {
          this.primaryQueryString = `?username=${btoa('aydaculha@gundogdu.k12.tr')}&password=${btoa('aydaculha')}`;
          this.secondaryQueryString = `?username=${btoa('aydaculha@gundogdu.k12.tr')}&password=${btoa('aydaculha')}`;
        } else {
          const username = this.username.split('@')[0];
          // eslint-disable-next-line
          const hexaUserName = username + '-de@agk88.com';
          const res = await userService.checkHexagonia(hexaUserName);
          if (res.data.success) {
            this.hexagoniaReady = res.data.success;
          }
          this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
          this.hexaDeStringText = `?username=${btoa(hexaUserName)}&password=${btoa(this.password+'-de')}`;
        }
        this.querystingHexaReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('4')}`;
        this.querystingAgkReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('14')}`;
        this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
        this.gndkwdemo1 = `?username=${btoa('gndkwdemo1')}&password=${btoa('123456')}`;
        this.gndkwdemo2 = `?username=${btoa('gndkwdemo2')}&password=${btoa('123456')}`;
        this.gndkwdemo3 = `?username=${btoa('gndkwdemo3')}&password=${btoa('123456')}`;
        this.gndkwdemo4 = `?username=${btoa('gndkwdemo4')}&password=${btoa('123456')}`;
        this.gndepbdemo1 = `?username=${btoa('kindergarten_3')}&password=${btoa('kindergarten_3')}`;
        this.gndepbdemo2 = `?username=${btoa('kindergarten_4')}&password=${btoa('kindergarten_4')}`;
        this.gndepbdemo3 = `?username=${btoa('kindergarten_5')}&password=${btoa('kindergarten_5')}`;
        this.projectUmbrella = `?username=${btoa('gnd04')}&password=${btoa('gnd04')}`;
      }
      this.ready = true;
    },
    ActivityBooks() {
      const studentClass = this.userDetails.organization.find(x => x.type === 'grade').name;
      let link = '';
      // console.log(Number(this.grade));
      if (Number(this.grade) === 4) {
        link = 'https://www.dropbox.com/sh/n7q4map98my9xzk/AACDVO4EKOgzupgK8_MjRfAPa?dl=0';
      } else if (Number(this.grade) === 3) {
        link = 'https://www.dropbox.com/sh/sglh4brk5ixukm3/AAAn0yz9OH61pK4CB4aEUpEma?dl=0';
      } else if (Number(this.grade) === 2) {
        link = 'https://www.dropbox.com/sh/ny9ci7j94waen6n/AAAyHNaDZXgP93anU8fFyK2qa?dl=0';
      } else if (Number(this.grade) === 1) {
        link = 'https://www.dropbox.com/sh/7291zld1bd5mzba/AAAC_-wp35ofaX9-SPJzfeVca?dl=0';
      } else if (Number(this.grade) === 5) {
        link = 'https://www.dropbox.com/sh/dp5kzmwga17cr4w/AAChhHEOjuIEAd0qXlaO8JDCa?dl=0';
        // if (studentClass === '5-B' || studentClass === '5-D' || studentClass === '5-E') { // C KURU
        // } else { // AB KURU
        //   link = '';
        // }
      } else if (Number(this.grade) === 6) {
        if (studentClass === '6-K') {// C KURU
          link = 'https://www.dropbox.com/sh/jpwtc1aqdtofh6p/AAA1OrLdr9b9KsaGnpWUQBiDa?dl=0';
        } else {// AB KURU
          link = 'https://www.dropbox.com/sh/jpwtc1aqdtofh6p/AAA1OrLdr9b9KsaGnpWUQBiDa?dl=0';
        }
      } else if (Number(this.grade) === 7) {
        if (studentClass === '7-C' || studentClass === '7-H' || studentClass === '7-M') {// C KURU
          link = 'https://www.dropbox.com/sh/7ps0j2mv0ed248a/AAAIzUNIL1sgjNmIfkLap4CIa?dl=0';
        } else {// AB KURU
          link = 'https://www.dropbox.com/sh/7ps0j2mv0ed248a/AAAIzUNIL1sgjNmIfkLap4CIa?dl=0';
        }
      } else if (Number(this.grade) === 8) {
        link = 'https://www.dropbox.com/sh/4e4nik6ih76si8c/AACfJVZGJx10kV3wi_9f-Y-ea?dl=0';
      } else if (Number(this.grade) === 9) {
        if (studentClass === '9-A' || studentClass === '9-B' || studentClass === '9-C' || studentClass === '9-D' || studentClass === '9-E') {
          link = 'https://www.dropbox.com/sh/mizwh5bx1nr62s1/AAAIlnke0U7TnFOeivcVpiC3a?dl=0';
        } else {
          link = 'https://www.dropbox.com/sh/6ryjof6wdmrbisf/AACez-YEUCxsmuP8DcqwYinwa?dl=0';
        }
      } else if (Number(this.grade) === 10) {
        if (studentClass === '10-A' || studentClass === '10-B' || studentClass === '10-C' || studentClass === '10-D' || studentClass === '10-E') {
          link = 'https://www.dropbox.com/sh/1w9g2tar1vgeews/AAAkAdqqFLh4rtTNa9pt3dn5a?dl=0';
        } else {
          link = 'https://www.dropbox.com/sh/h02w2sxelxn4u3z/AAD5FG5pFw28tb1OcQEMhDq4a?dl=0';
        }
      } else {
        link = '';
      }
      return link;
    },
    TeacherResources() {
      const teacherClass = this.userDetails.organization.find(x => x.type === 'grade').name;
      // console.log('teacherClass', teacherClass);
      let link = '';
      if (teacherClass === 'Kindergarten 5') {  
          link = 'https://www.dropbox.com/sh/05238zmyjq6wuvu/AABS0guFueypBFM8OPmFE_RHa?dl=0';
      }else if (teacherClass === 'Kindergarten 4') {  
          link = 'https://www.dropbox.com/sh/05238zmyjq6wuvu/AABS0guFueypBFM8OPmFE_RHa?dl=0';
      }else if (teacherClass === 'Kindergarten 3') {  
          link = 'https://www.dropbox.com/sh/05238zmyjq6wuvu/AABS0guFueypBFM8OPmFE_RHa?dl=0';
      } else if (Number(this.grade) === 4) {
          link = 'https://www.dropbox.com/sh/wwj3lto5htdfe7a/AAB_iJRh28beHPHY4KZTVv2ja?dl=0';
      } else if (Number(this.grade) === 3) {
          link = 'https://www.dropbox.com/sh/wwj3lto5htdfe7a/AAB_iJRh28beHPHY4KZTVv2ja?dl=0';
      } else if (Number(this.grade) === 2) {
          link = 'https://www.dropbox.com/sh/wwj3lto5htdfe7a/AAB_iJRh28beHPHY4KZTVv2ja?dl=0';
      } else if (Number(this.grade) === 1) {
          link = 'https://www.dropbox.com/sh/wwj3lto5htdfe7a/AAB_iJRh28beHPHY4KZTVv2ja?dl=0';
      } else if (Number(this.grade) === 5) {
          link = 'https://www.dropbox.com/sh/5tipi5129tzjgk1/AAAgqzEdxBlVRiUgZMGpgNKSa?dl=0';
      } else if (Number(this.grade) === 6) {
          link = 'https://www.dropbox.com/sh/5tipi5129tzjgk1/AAAgqzEdxBlVRiUgZMGpgNKSa?dl=0';
      } else if (Number(this.grade) === 7) {
          link = 'https://www.dropbox.com/sh/5tipi5129tzjgk1/AAAgqzEdxBlVRiUgZMGpgNKSa?dl=0';
      } else if (Number(this.grade) === 8) {
          link = 'https://www.dropbox.com/sh/5tipi5129tzjgk1/AAAgqzEdxBlVRiUgZMGpgNKSa?dl=0';
      } else if (Number(this.grade) === 9) {
          link = 'https://www.dropbox.com/sh/asgqd4vjl35sis5/AACBt0ycWtGreeuegHx6jySpa?dl=0';
      } else if (Number(this.grade) === 10) {
          link = 'https://www.dropbox.com/sh/asgqd4vjl35sis5/AACBt0ycWtGreeuegHx6jySpa?dl=0';
      } else if (Number(this.grade) === 11) {
          link = 'https://www.dropbox.com/sh/asgqd4vjl35sis5/AACBt0ycWtGreeuegHx6jySpa?dl=0';
      } else {
          link = '';
      }
      return link;
    },
    lp() {
      swal.fire({
        title: 'Literacy Planet',
        html: ' <b>LEVEL 1:</b>  agkgrade1@gundogdu.k12.tr<br><b>LEVEL 2:</b>  agkgrade2@gundogdu.k12.tr<br><b>LEVEL 3:</b>  agkgrade3@gundogdu.k12.tr<br><b>LEVEL 4:</b>  agkgrade4@gundogdu.k12.tr<br><br><b>Default Password:</b> ues1234',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Go',
      }).then((result) => {
        if (result.value) {
          window.open('https://app.literacyplanet.com/login', '_blank');
        }
      });
    },
    pb() {
      if (this.roleType === 'teacher') {
        // console.log(this.userDetails);
        this.personalbestUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : "";
        this.personalbestPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : "";
      } else if (this.roleType === 'student') {
        this.personalbestUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : "";
        this.personalbestPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : "";
        // this.personalbestUser = 'richmondelt18+st06b@gmail.com';
        // this.personalbestPass = 'pass';
      }
      // console.log(this.roleType);

      swal.fire({
        title: 'Personal Best',
        html: `<b>USERNAME:</b> ${this.userDetails.email} <hr><b>PASSWORD:</b><br> Şifreniz, kayıt sırasında kullandığınız agk-okul mail adresinize gönderilmiştir.  Mail'inize gelen 'Richmond Welcome' linkinden şifrenizi oluşturunuz.`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Go',
      }).then((result) => {
        if (result.value) {
          window.open('https://richmondlp.com/login', '_blank');
        }
      });
    },
    lc() {
        this.logCompassSchool = this.userDetails.compass_school;
        this.logCompassUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : '';
        this.logCompassPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : '';

      if(this.userDetails.role_type === 'teacher') {
        swal.fire({
        title: 'Log Compass',
        html: `        
          <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
                      <div class="alert alert-danger" role="alert">
                        Sevgili Öğretmenimiz, Log Compass sistemindeki güncelleme işleminden dolayı hesabınıza şu anda giriş yapamıyorsunuz. Rapor ya da diğer talepleriniz için Emre Uyan (emre.uyan@eltturkey.com) ile iletişime geçebilirsiniz.
                      </div>
          </div>`,
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
      });
      }else{
        swal.fire({
          title: 'Log Compass',
          html: `        
          <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
                      <div class="alert alert-info" role="alert">
                        Aşağıdaki kullanıcı bilgileri ile Go to Compass linkine tıklayarak hesabınıza giriş yapabilirsiniz.
                      </div>
                      <table class="table table-sm table-striped">
                        <tbody>
                          <tr>
                            <td class="table-primary"><b>School name</b></td>
                            <td class="table-light"><b>${this.logCompassSchool}</b></td>
                          </tr>
                          <tr>
                            <td class="table-primary"><b>User name</b></td>
                            <td class="table-light"><b>${this.logCompassUser}</b></td>
                          </tr>
                          <tr>
                            <td class="table-primary"><b>Password</b></td>
                            <td class="table-light"><b>${this.logCompassPass}</b></td>
                          </tr>
                        </tbody>
                      </table>
                  </div>`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Go to Compass',
        }).then((result) => {
          if (result.value) {
            window.open('https://www.logcompass.com', '_blank');
          }
        });

      }

    },

    async RLP() {
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.level = this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.level = this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name =  this.level = this.userDetails.organization.find(x => x.type === 'campus').name;

          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
        }

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();

        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        this.ready = true;
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
